/* eslint-disable camelcase */
import { __awaiter, __generator } from "tslib";
import { PublicChannel } from '@wix/wix-vod-api-site-based/dist/src/public/services/channel/channel';
import { toError } from '../../parsers/transport-error';
import { keysToCamelCase, keysToSnakeCase, } from '@wix/wix-vod-shared/dist/src/common/utils/object';
import { CHANNEL_TYPES } from '@wix/wix-vod-constants/dist/common/channel-types';
import { parseChannel } from './parsers';
import { createCtx } from '@wix/wix-vod-shared/dist/src/common/utils/context';
import { isGraphAPIEnabled } from '@wix/wix-vod-shared/dist/src/common/utils/experiments';
import { PurchasesService } from '@wix/wix-vod-gql-api/dist/src/public/purchases/purchases';
import { PaymentType } from '@wix/wix-vod-constants/dist/api-types/enums';
export var createService = function () { return new PublicChannel(); };
export var service = createService();
export var ctx = createCtx();
export var getVideoPreviewUrls = function (channelId, instance) {
    // const service = ctx.getValue();
    return service
        .getVideoPreviewUrls(channelId, instance)
        .then(function (response) { return response.data; });
};
export var getMemberInfo = function (channelId, instance, _a) {
    var _b = _a === void 0 ? {} : _a, hydratedMemberInfo = _b.hydratedMemberInfo;
    return __awaiter(void 0, void 0, void 0, function () {
        var purchasesService, purchases, items, _c, _d, error_1;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    if (!isGraphAPIEnabled()) return [3 /*break*/, 2];
                    purchasesService = new PurchasesService(instance);
                    return [4 /*yield*/, purchasesService.getPurchases()];
                case 1:
                    purchases = _e.sent();
                    items = purchases.map(function (purchase) {
                        var _a;
                        var item = {
                            dgsInfo: (_a = {},
                                _a[purchase.type] = purchase,
                                _a),
                            listId: channelId,
                        };
                        // in some cases we check if itemId is present - means that it is channel subscription (not video rent/sale) :)
                        if (purchase.type !== PaymentType.SUBSCRIPTION) {
                            item.itemId = purchase.id;
                        }
                        return item;
                    });
                    return [2 /*return*/, {
                            data: {
                                items: items,
                            },
                        }];
                case 2:
                    _e.trys.push([2, 5, , 6]);
                    _c = keysToCamelCase;
                    _d = hydratedMemberInfo;
                    if (_d) return [3 /*break*/, 4];
                    return [4 /*yield*/, service.getMemberInfo(channelId, instance)];
                case 3:
                    _d = (_e.sent());
                    _e.label = 4;
                case 4: return [2 /*return*/, _c.apply(void 0, [_d])];
                case 5:
                    error_1 = _e.sent();
                    return [2 /*return*/, toError(error_1.response)];
                case 6: return [2 /*return*/];
            }
        });
    });
};
export var getPublic = function (channelId, _a) {
    var _b = _a === void 0 ? {} : _a, hydratedChannel = _b.hydratedChannel, templateMetaSiteId = _b.templateMetaSiteId;
    return __awaiter(void 0, void 0, void 0, function () {
        var response, _c, channel, custom_cover_url, cover_url, featured_item, channel_type;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    _c = hydratedChannel;
                    if (_c) return [3 /*break*/, 2];
                    return [4 /*yield*/, service.getInfo(channelId, null, {
                            params: { metaSiteId: templateMetaSiteId },
                        })];
                case 1:
                    _c = (_d.sent());
                    _d.label = 2;
                case 2:
                    response = _c;
                    try {
                        channel = keysToSnakeCase(response.data);
                        channel.youtube_data = keysToCamelCase(channel.youtube_data);
                        custom_cover_url = channel.custom_cover_url, cover_url = channel.cover_url, featured_item = channel.featured_item, channel_type = channel.channel_type;
                        if (!custom_cover_url &&
                            !cover_url &&
                            featured_item &&
                            channel_type !== CHANNEL_TYPES.YOUTUBE_FEED) {
                            channel.cover_url = extractCoverFromVideoItem(channel.featured_item);
                        }
                        return [2 /*return*/, { data: parseChannel(channel) }];
                    }
                    catch (error) {
                        return [2 /*return*/, toError(error.response)];
                    }
                    return [2 /*return*/];
            }
        });
    });
};
var extractCoverFromVideoItem = function (videoItem) {
    return videoItem.custom_cover_url || videoItem.cover_url;
};
