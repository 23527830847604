import { createAction } from 'redux-actions';
import { openFullScreenOverlay } from '../../../../shared/utils/open-overlay';
import { createProxy } from '../../../../shared/worker/lib';
export var NAMES = {
    OPEN_FULL_SCREEN_MODAL_VIDEO: 'CLIENT.FULL_SCREEN_MODAL.VIDEO.OPEN',
    CLOSE_FULL_SCREEN_MODAL_VIDEO: 'CLIENT.FULL_SCREEN_MODAL.VIDEO.CLOSE',
};
var openFullScreenModalVideo = createAction(NAMES.OPEN_FULL_SCREEN_MODAL_VIDEO);
export var closeFullScreenVideoOverlay = createAction(NAMES.CLOSE_FULL_SCREEN_MODAL_VIDEO);
export var openFullScreenVideoOverlay = createProxy('openFullScreenVideoOverlay', function (channelId, videoId, autoplay, onClose) {
    if (autoplay === void 0) { autoplay = false; }
    return function (dispatch) {
        dispatch(openFullScreenOverlay("#/channel/".concat(channelId, "/video/").concat(videoId, "?autoplay=").concat(autoplay), onClose));
        dispatch(openFullScreenModalVideo());
    };
});
